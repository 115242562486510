@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap');

body {
  font-family: 'DM Sans', sans-serif;
  /* max-width: 1150px;
  margin: auto; */
}

.active {
  color: #3575E2;
  font-weight: 500;
}

a {
  text-decoration: none;

}

li {
  list-style: none;
}

.btns {
  padding: 10px 20px;
  margin-right: 6px;
  background: transparent;
  border: none;
  border: 0.6px solid #ccc;
  border-radius: 5px;
  color: #323232;
  cursor: pointer;
}

/* jobs page */

.card {
  margin: 10px;
  /* border: 2px solid #ededed; */
  /* padding: 20px; */
  cursor: pointer;
}


.card-title {
  margin-bottom: 1rem;
}

.card-reviews {
  margin-bottom: 1rem;
  display: flex;
}

.rating-star {
  color: #d5ab55;
}

.total-reviews {
  font-size: 0.9rem;
  margin-left: 10px;
}

.card-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bag-icon {
  color: #535353;
}

.sidebar-title {
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 20px;
}

.sidebar-items {
  margin-top: 20px;
}

.sidebar-label-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sidebar-label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.all {
  background: linear-gradient(blue, crimson);
}

.sidebar-label-container:hover input~.checkmark {
  background-color: #ccc;
}

.sidebar-label-container input:checked~.checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sidebar-label-container input:checked~.checkmark:after {
  display: block;
}

.sidebar-label-container .checkmark:after {
  top: 6.4px;
  left: 6.4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

.line {
  margin-top: 3rem;
  border-color: #f7f7f7;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 0.3rem;
  font-family: Arial, sans-serif;
}

.pagination__page {
  list-style: none;
}

.pagination__link {
  padding: 0.5rem 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #0EA5E9;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.pagination .pagination__link--active {
  background-color: #0EA5E9 !important;
  color: white !important;
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  font-weight: 900;
}

.pagination__previous,
.pagination__next {
  padding: 0.5rem 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #0EA5E9;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.pagination__break {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0EA5E9;
  /* padding: 0.5rem 0.75rem; */
  cursor: default;
}

.pagination__link--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


/* create a job page */

.create-job-flex {
  @apply flex flex-col lg:flex-row items-center justify-between gap-8;
}

.create-job-input {
  @apply block w-full flex-1 bg-white py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6
}